export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/cover.jpg?updatedAt=1691689029725`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/welcome.jpg?updatedAt=1691689033630`;
export const BG_WISHLIST = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-wishlist_2i8I7jb5h.jpg?updatedAt=1638541154280`;
export const BG_RSVP = `https://user-images.githubusercontent.com/10141928/146665436-20914399-2fc0-4b9e-b05e-0e961c4320e6.jpg`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-pre-livestream_REXdy4RJy.jpg?updatedAt=1638541152599`;
export const BG_FOOTER = `https://ik.imagekit.io/zaanfa2/rena-bagas/footer.jpg?updatedAt=1687864184599`;
export const BG_START_JOURNEY = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-start-journey_DwkmI92EvqJ.jpg?updatedAt=1638541149699`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/146198254-9f2df09b-7cc7-4cd9-bdce-bd0563a23a77.jpg`;
export const BG_GIFTCARD = `https://user-images.githubusercontent.com/10141928/147398898-8b47c166-f479-48f9-a5b6-5d80ffdd9358.jpeg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/filter-new.jpg?updatedAt=1692099842533`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/welcome-alt.jpg?updatedAt=1691689029995`;

export const IMG_COVER = `https://ik.imagekit.io/invitatoid/template-rainajanssen/cover_qAEf5Jbyv.jpg?updatedAt=1638541165051`;
export const IMG_LOGO = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/logo.png?updatedAt=1691685942563`;

export const IMG_MAN = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/man.jpg?updatedAt=1691689029954`;
export const IMG_GIRL = `https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/girl.jpg?updatedAt=1691689030015`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;
export const IMG_QR_COVER = IMG_LOVE_STORY;

export const IMG_PHOTO_1 = 'https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/g1.jpg?updatedAt=1691689029617';
export const IMG_PHOTO_2 = 'https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/g2.jpg?updatedAt=1691689029819';
export const IMG_PHOTO_CONTENT = 'https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/g3.jpg?updatedAt=1691689029706';

export const IMG_AYAT = 'https://ik.imagekit.io/bxwgcojbe/kelvin-livinna/footer.jpg?updatedAt=1691689029696';

export const IMG_SLIDE_1 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_1_n8StaSLkS.jpg?updatedAt=1638541151789`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_2_11_dNMMPw.jpg?updatedAt=1638541152275`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;
